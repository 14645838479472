<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('SKUs') }}
    </p>
    <div class="sm-content mt-15">
      <section class="table-content-part">
        <div class="d-flex justify-content-between">
          <div class="search-bar">
            <p class="search-label">{{ $t('Search') }}</p>
            <v-text-field
              v-model="search"
              single-line
              hide-details
              class="search-form"
            ></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="profile_list"
          class="cus-table editable-table main-table striped-table"
          item-key="id"
        >
          <template v-slot:[`item.active`]="{ item }">
            <div style="display: flex; justify-content: center;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.active"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.required`]="{ item }">
            <div style="display: flex; justify-content: center;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.required"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ }">
            <v-icon medium color="dark" @click="showModal('edit')">edit</v-icon>
          </template>
        </v-data-table>
      </section>

      <div class="content-margin mb-20 d-flex">
        <button class="primary-btn primary-btn_sm mt-15 bg-yellow" @click="showModal('create')">
          {{ $t("New") }}
        </button>
      </div>
    </div>

    <!-- modal -->
    <modal
      :width="500"
      :adaptive="true"
      class="cp-product-codes_modal CEModal modal-scroll-bar"
      name="createEditModal"
    >
      <div class="modal-filter mb-10" v-if="modalType=='edit'">
        <label>{{ $t('Select SKU') }}</label>
        <div class="icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="">
        </div>
      </div>
      <div class="modal-filter mb-10" v-else-if="modalType=='create'">
        <label>{{ $t('Enter a new part number there') }}</label>
        <input type="text" class="input" />
      </div>
      <div class="d-flex justify-content-between w-75 mb-15">
        <div>
          <div class="mb-15">
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px-5">{{ $t('Active') }}</label>
          </div>
          <div>
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px-5">{{ $t('Required') }}</label>
          </div>
        </div>
        <div>
          <div class="mb-15">
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px-5">{{ $t('Off') }}</label>
          </div>
          <div>
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px-5">{{ $t('Optional') }}</label>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <button class="primary-btn primary-btn_sm">
          {{ $t("New") }}
        </button>
        <button class="primary-btn primary-btn_sm bg-red2" @click="closeModal">
          {{ $t("Void") }}
        </button>
      </div>
    </modal>
  </main> 
</template>

<script>
export default {
  name: 'CPProductCodes',
  data() {
    return {
      search: '',
       headers: [
        {
          text: this.$t('Name of SKU'),
          align: 'center',
          sortable: false,
          value: 'sku_name',
        },
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: this.$t('Required'),
          align: 'center',
          sortable: false,
          value: 'required',
        },
        {
          text: this.$t('Required'),
          align: 'center',
          sortable: false,
          value: 'edit',
        }
      ],
      profile_list: [
        {
          sku_name: 'iCount',
          active: true,
          required: true,
          edit: ''
        },
        {
          sku_name: 'דלק',
          active: false,
          required: false,
          edit: ''
        },
        {
          sku_name: 'iCount',
          active: true,
          required: false,
          edit: ''
        }
      ],
      modalType: 'edit'
    }
  },
  methods: {
    showModal(type) {
      this.modalType = type;
      this.$modal.show("createEditModal");
      $("html").css("overflow", "hidden");
    },
    closeModal() {
      this.$modal.hide("createEditModal");
      $("html").css("overflow", "auto");
    },
  },
  computed: {
  },
}
</script>

<style lang="scss">
  .cp-product-codes_modal .vm--modal {
    padding: 40px;
    .modal-filter {
      max-width: 280px;
    }
  }
</style>